<template>
    <TheAppbar></TheAppbar>
    <TheDrawer></TheDrawer>
    <router-view class="view" v-slot="{ Component }">
        <transition name="fade" mode="in-out">
            <component :is="Component"></component>
        </transition>
    </router-view>
</template>


<script setup>
import { useRouter } from "vue-router";
import {useAppStore} from "./stores/nagyretitoStores.js";
import TheDrawer from "@/nagyretito/includes/TheDrawer.vue";
import TheAppbar from "@/nagyretito/includes/TheAppbar.vue";

const router = useRouter();
const appStore = useAppStore();
</script>
