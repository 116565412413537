<template>
    <div id="appbar">
        <div id="appbar-title">
            <router-link to="/"><svg width="110" height="38" aria-label="Homepage link"><use href="nagyretito-icons.svg#nagyretito"></use></svg></router-link>
        </div>
        <div id="appbar-hamburger">
            <button @click="appStore.toggleDrawer()" class="button secondary icon-button" aria-label="Hamburger button">
                <svg width="30" height="30"><use href="nagyretito-icons.svg#hamburger"></use></svg>
            </button>
        </div>
        <nav id="appbar-nav"
             :data-home="$route.name === 'Kezdőlap'? true:''"
             :data-information="$route.name === 'Információ'? true:''"
             :data-rules="$route.name === 'Szabályzat'? true:''"
             :data-fishes="$route.name === 'Halak'? true:''"
             :data-booking="$route.name === 'Foglalás'? true:''"
             :data-contact="$route.name === 'Kapcsolat'? true:''">
            <router-link to="/">Kezdőlap</router-link>
            <router-link to="/informacio">Információ</router-link>
            <router-link to="/szabalyzat">Szabályzat</router-link>
            <router-link to="/halak">Halak</router-link>
            <router-link to="/foglalas">Foglalás</router-link>
            <router-link to="/kapcsolat">Kapcsolat</router-link>
        </nav>
        <div id="appbar-user">USER</div>
    </div>
</template>

<style scoped>
#appbar {
    position: fixed;
    z-index: 256;
    top: 0;left: 0;right: 0;
    height: 56px;
    display: flex;
    justify-content: space-between;
    background-color: rgba(30,30,30,.5);
    backdrop-filter: blur(16px) saturate(80%);
    user-select: none;
}
#appbar-title {
    margin-left: 8px;
    width: 130px;
    display: flex;
    align-items: center;
}
#appbar-title svg {
    fill: var(--light-font-color);
}
#appbar-title a:hover svg {
    fill: var(--site-color-orange);
}

#appbar-nav {
    display: none;
    position: relative;
    align-items: center;
}
@media all and (min-width: 1120px) { #appbar-nav {display: flex;} }

#appbar-nav > a {
    display: block;
    height: 32px;
    line-height: 32px;
    text-align: center;
    width: 120px;
    color: var(--light-font-color);
    text-transform: uppercase;
    text-decoration: none !important;
    font-family: "Open Sans Bold", sans-serif;
    font-size: 14px;
    font-weight: 600;
    transition: all 300ms;
}
#appbar-nav > a:hover {
    color: var(--site-color-orange);
    transition: all 300ms;
}
#appbar-nav::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 120px;
    height: 4px;
    background-color: var(--site-color-orange);
    transition: ease-in-out .3s;
    border-radius: 5px;
}
#appbar-nav[data-home="true"]::after {left: 0;}
#appbar-nav[data-information="true"]::after {left: 120px;}
#appbar-nav[data-rules="true"]::after {left: 240px}
#appbar-nav[data-fishes="true"]::after {left: 360px}
#appbar-nav[data-booking="true"]::after {left: 480px}
#appbar-nav[data-contact="true"]::after {left: 600px}

#appbar-hamburger {
    margin-right: 8px;
    display: flex;
    align-items: center;
}
@media all and (min-width: 1120px) { #appbar-hamburger {display: none;} }

#appbar-user {
    border: 1px solid black;
    display: none;
    margin-right: 0;
    width: 130px;
}
@media all and (min-width: 1120px) { #appbar-user {display: block;} }

#appbar-user {
    visibility: hidden;
}

</style>

<script setup>
import {useAppStore} from "../stores/nagyretitoStores.js";
const appStore = useAppStore();
</script>


