<template>
    <div id="drawer" v-bind:class="{ active: appStore.getDrawer }">
        <div id="drawer-header">
            <svg width="120" height="40"><use href="nagyretito-icons.svg#nagyretito"></use></svg>
            <button type="button" class="button secondary icon-button" @click="appStore.toggleDrawer()" aria-label="hamburger button">
                <svg width="24" height="24"><use href="nagyretito-icons.svg#close"></use></svg>
            </button>
        </div>
        <nav id="drawer-nav">
            <router-link to="/" @click="appStore.toggleDrawer()">Kezdőlap</router-link>
            <router-link to="/informacio" @click="appStore.toggleDrawer()">Információ</router-link>
            <router-link to="/szabalyzat" @click="appStore.toggleDrawer()">Szabályzat</router-link>
            <router-link to="/halak" @click="appStore.toggleDrawer()">Halak</router-link>
            <router-link to="/foglalas" @click="appStore.toggleDrawer()">Foglalás</router-link>
            <router-link to="/kapcsolat" @click="appStore.toggleDrawer()">Kapcsolat</router-link>
        </nav>
    </div>
</template>

<style scoped>
#drawer {
    z-index: 256;
    position: fixed;
    width: 250px;
    height: 100%;
    background-color: var(--widget-background);
}
#drawer {
    right: 0;
    transform: translateX(250px);
    transition: 300ms ease-in-out;
}
#drawer.active {
    transform: translateX(0px);
    transition: 300ms ease-in-out;
}

#drawer-header {
    display: flex;
    padding: 8px 8px;
    margin-bottom: 24px;
    justify-content: space-between;
    align-items: center;
}

#drawer-nav {
    padding-left: 8px;
}
#drawer-nav > a {
    display: block;
    width: 230px;
    height: 44px;
    line-height: 44px;
    text-indent: 12px;
    color: var(--font-color);
    text-decoration: none;
    text-transform: uppercase;
    margin: 8px 0 8px 0;
}
#drawer-nav > a.router-link-active {
    background-color: darkorange;
}
</style>

<script setup>
import {useAppStore} from "../stores/nagyretitoStores.js";
const appStore = useAppStore();
</script>


